import { createContext, useState, useContext } from "react";

// Create the BusinessContext
const BusinessContext = createContext();

// Create a provider component
export function BusinessProvider({ children }) {
  const [isBusinessModalOpen, setIsBusinessModalOpen] = useState(false);

  // Functions to control the modal state
  const openBusinessModal = () => {
    setIsBusinessModalOpen(true);
  };

  const closeBusinessModal = () => {
    setIsBusinessModalOpen(false);
  };

  return (
    <BusinessContext.Provider
      value={{ isBusinessModalOpen, setIsBusinessModalOpen }}
    >
      {children}
    </BusinessContext.Provider>
  );
}

// Create a custom hook to use the BusinessContext
export function useBusiness() {
  return useContext(BusinessContext);
}
